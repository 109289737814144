.about-company-wrapper {
  background-color: #f0f8ff;
  .about-company-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 48px 48px 0px 48px;
    @media only screen and (max-width: 768px) {
      padding: 24px;
    }
    .about-company-content {
      .about-company-pic {
        order: 1;
        @media only screen and (max-width: 1024px) {
          text-align: center;
        }
        .about-company-img {
          @media only screen and (max-width: 1024px) {
            width: 70%;
          }
          @media only screen and (max-width: 600px) {
            width: 90%;
          }
        }
        @media only screen and (max-width: 900px) {
          order: 2;
        }
      }
      .about-company-info {
        order: 2;
        text-align: right;
        .about-company-infoHeadText {
          color: #00096f;
          font-weight: 600;
          font-size: 56px;
          text-align: left;
          padding-bottom: 1rem;
          margin-bottom: 0;
          @media only screen and (max-width: 768px) {
            font-size: 36px;
          }
        }
        .about-company-infoParaText {
          color: #2e2d2d;
          line-height: 1.73;
          padding-bottom: 1rem;
          font-size: 1em;
          text-align: left;
          @media only screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
        @media only screen and (max-width: 900px) {
          order: 1;
          text-align: justify;
        }
      }
    }
  }
}
