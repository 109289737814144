.app-footer-wrapper {
  background-color: #016286;
  .app-footer-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 36px 48px 24px 48px;
    @media only screen and (max-width: 900px) {
      text-align: center;
    }
    @media only screen and (max-width: 768px) {
      padding: 24px;
    }
    .app-footer-infoHeadText {
      color: #ffffff;
      font-weight: 500;
      font-size: 28px;
      margin-bottom: 0;
      padding: 0px 16px 18px 16px;
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        padding-bottom: 16px;
      }
    }
    .app-footer-contentItem {
      .footer-contactMenuList {
        padding: 0;
        @media only screen and (max-width: 900px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .footer-contactMenuListItem {
          align-items: start;
          justify-content: center;
          color: #ffffff;
          padding-top: 0;
          padding-bottom: 16px;
          &:hover {
            background-color: transparent;
          }
          .footer-contactMenuListItemIcon {
            color: #ffffff;
          }
        }
      }
      .app-footer-sloganText {
        color: #ffffff;
        font-size: 14px;
        padding: 0px 16px 16px 16px;
        box-sizing: border-box;
        display: block;
        width: 84%;
        @media only screen and (max-width: 900px) {
          width: 100%;
          text-align: center;
        }
        @media only screen and (max-width: 600px) {
          width: 100%;
          text-align: left;
        }
      }
      .app-footer-copyrightText {
        border-top: 1px solid #ffffff;
        padding-top: 2em;
        text-align: center;
        color: #ffffff;
      }
      @media only screen and (max-width: 900px) {
        padding-left: 0%;
      }
    }
    .app-footer-contentItemQuickMenu {
      padding-left: 12%;
      @media only screen and (max-width: 1280px) {
        padding-left: 7%;
      }
      @media only screen and (max-width: 1024px) {
        padding-left: 5%;
      }
      @media only screen and (max-width: 900px) {
        padding-left: 0%;
      }
    }
    .app-footer-contentItemContactInfo {
      padding-left: 16%;
      .footer-contactMenuListItem {
        @media only screen and (max-width: 900px) {
          width: 25%;
          text-align: left;
        }
        @media only screen and (max-width: 768px) {
          width: 30%;
          text-align: left;
        }
        @media only screen and (max-width: 600px) {
          width: 40%;
          text-align: left;
        }
        @media only screen and (max-width: 450px) {
          width: 55%;
          text-align: left;
        }
        @media only screen and (max-width: 360px) {
          width: 60%;
          text-align: left;
        }
      }

      @media only screen and (max-width: 1280px) {
        padding-left: 10%;
      }
      @media only screen and (max-width: 1024px) {
        padding-left: 8%;
      }
      @media only screen and (max-width: 900px) {
        padding-left: 0%;
      }
    }
  }
}
