.vehiclesCategories-wrapper {
  background-color: #f0f8ff;
  .vehiclesCategories-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 48px;
    @media only screen and (max-width: 768px) {
      padding: 24px;
    }
    .vehiclesCategories-infoHeadText {
      color: #00096f;
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      padding-bottom: 1em;
      margin-bottom: 0;
      @media only screen and (max-width: 768px) {
        font-size: 24px;
      }
    }
    .vehiclesCategories-paraText {
      color: #2e2d2d;
      line-height: 1.73;
      padding-bottom: 2em;
      margin-bottom: 0;
      text-align: center;
      @media only screen and (max-width: 768px) {
        padding-bottom: 1.5em;
        text-align: justify;
      }
    }
    .vechiclesCategories-content {
      display: flex;
      flex-direction: row;
      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
      .vechiclesCategories-contentCard {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        .vechiclesCategories-contentHeadText {
          text-align: center;
          padding: 16px;
          margin: 0;
          box-sizing: border-box;
        }
        .vechiclesCategories-contentImg {
          object-fit: contain;
          padding: 0px 16px;
          box-sizing: border-box;
        }
        .vechiclesCategories-contentEconomyImg {
          width: 77%;
          margin: 0 auto;
        }
        .vechiclesCategories-contentDescriptionText {
          padding: 16px;
          box-sizing: border-box;
        }
      }
    }
  }
}
