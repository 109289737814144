.app-header {
  .app-header-content {
    .app-header-appBar {
      background-color: #ffffff;
      padding: 1rem 0;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
      .app-headContact {
        color: #525252;
        padding-bottom: 1rem;
        text-align: right;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        .app-headMailText,
        .app-headPhoneText {
          display: inline-block;
        }
        .app-headMailText {
          float: left;
          padding-left: 16px;
          @media only screen and (max-width: 600px) {
            font-size: 0.77em;
          }
        }
        .app-headPhoneText {
          float: right;
          padding-right: 16px;
          @media only screen and (max-width: 600px) {
            font-size: 0.77em;
          }
        }
        .app-headPhoneIcon {
          vertical-align: middle;
          color: #016286;
          padding-right: 2px;
        }
        &::after {
          content: "";
          display: block;
          clear: both;
        }
      }
      @media only screen and (max-width: 600px) {
        padding: 0.5rem 0;
      }
      .app-header-toolbar {
        justify-content: center;
        .header-logo-img {
          cursor: pointer;
        }
        .header-ds-menu {
          width: 40%;
          align-items: center;
          justify-content: space-between;
          .app-header-menuText {
            color: #525252;
            color: #016286;
            font-size: 1em;
            text-transform: capitalize;
            &:hover {
              background-color: transparent;
              color: #009688;
              color: #016286;
              color: #ff9800;
            }
          }
          @media only screen and (max-width: 1680px) {
            width: 45%;
          }
          @media only screen and (max-width: 1366px) {
            width: 50%;
          }
          @media only screen and (max-width: 1080px) {
            width: 65%;
          }
          @media only screen and (max-width: 768px) {
            width: 90%;
          }
        }
        .header-mb-menu {
          justify-content: space-between;
          align-items: center;
          width: 100%;
          color: #525252;
          .header-menu-icon svg {
            color: #ffffff;
            background-color: #016286;
            padding: 0.5rem;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.app-drawer {
  .app-drawer-menuListText {
    color: #00096f;
  }
  .app-drawer-menuListIcon {
    color: #00096f;
  }
}
