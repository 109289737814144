.ourServices-wrapper {
  background-color: #fdfdf8;
  .ourServices-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 48px;
    @media only screen and (max-width: 768px) {
      padding: 24px;
    }
    .ourServices-infoHeadText {
      color: #00096f;
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      padding-bottom: 70px;
      margin-bottom: 0;
      @media only screen and (max-width: 768px) {
        font-size: 24px;
      }
    }
    .ourServices-content {
      .ourServices-contentItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .ourServices-HeadText {
          color: #282460;
          font-size: 33px;
          font-weight: 400;
          margin-bottom: 0;
          padding-bottom: 1rem;
          line-height: 1.24;
          @media only screen and (max-width: 600px) {
            line-height: 1.32;
          }
          .yellow-heading-text {
            color: #fdb827;
            font-weight: 500;
          }
        }
        .ourServices-subHeadText {
          color: #23233c;
          font-size: 16px;
          font-weight: 700;
          padding-bottom: 0.5rem;
        }
        .ourServices-ParaText {
          color: #23233c;
          font-size: 14px;
        }
        .ourServices-infoImg {
          width: 95%;
        }
      }
      .ourServices-contentItemComfort {
        .ourServices-subHeadText {
          padding: 0;
        }
        .ourServices-ParaText {
          padding-bottom: 1rem;
        }
      }
    }
    .ourServices-content-mobileView {
      .ourServices-contentItem {
        .ourServices-HeadText {
          font-size: 23px;
          font-weight: 700;
        }
        .ourServices-subHeadText {
          font-size: 14px;
        }
        .ourServices-ParaText {
          font-size: 14px;
        }
      }
    }
  }
}
