.app-banner-wrapper {
  padding-top: 140px;
  background-color: #f0f8ff;
  @media only screen and (max-width: 600px) {
    padding-top: 112px;
  }
  .app-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    max-width: 1280px;
    margin: 0 auto;
    padding: 48px;
    background-image: url("../../assets/img/banner-bg-car.png");
    background-repeat: no-repeat;
    background-position: 100% 85%;
    @media only screen and (max-width: 768px) {
      align-items: start;
      padding: 24px 24px 14em 24px;
      background-position: 100% 95%;
    }
    .app-banner-content {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      width: 50%;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      .app-banner-headText {
        color: #00096f;
        font-weight: 600;
        font-size: 56px;
        padding-bottom: 1rem;
        @media only screen and (max-width: 768px) {
          font-size: 36px;
        }
      }
      .app-banner-paraText {
        color: #2e2d2d;
        line-height: 1.73;
        padding-bottom: 1rem;
      }
      .app-banner-buttonWrap {
        width: 100%;
      }
    }
  }
}
