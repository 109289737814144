.whyChooseUs-wrapper {
  background-color: #f0f8ff;
  .whyChooseUs-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 48px 48px 70px 48px;
    @media only screen and (max-width: 768px) {
      padding: 24px;
    }
    .whyChooseUs-infoHeadText {
      color: #00096f;
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      padding-bottom: 1em;
      margin-bottom: 0;
      @media only screen and (max-width: 768px) {
        font-size: 24px;
      }
    }
    .whyChooseUs-content {
      .whyChooseUs-contentGrid {
        display: flex;
      }
      .whyChooseUs-contentCard {
        padding: 2em;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        min-height: 200px;
        align-items: center;
        display: flex;
        flex-direction: column;
        .whyChooseUs-contentCardIcon {
          text-align: center;
          padding-bottom: 1em;
          .whyChooseUs-contentCardIconItem {
            font-size: 4em;
            color: #ffd53b;
          }
        }
        .whyChooseUs-infoHeadText {
          font-size: 16px;
          margin-bottom: 0;
          color: #0b0b0d;
        }
        .whyChooseUs-infoParaText {
          font-size: 14px;
          margin-bottom: 0;
          text-align: center;
        }
      }
    }
  }
}
